<template>
  <section>
    <h2>01-2022-S423 / Proveedor / Amazon US</h2>
    <div class="my-8">
        <v-row>
            <v-col cols="6" sm="4" md="3">
                <v-btn block text color="success" @click="dialogoConfirmacion = true"><v-icon>mdi-send</v-icon> Aprobar solicitud</v-btn>
            </v-col>
            <v-col cols="6" sm="4" md="3">
                <v-btn block text color="red" @click="dialogoCancelacion = true"><v-icon>mdi-cancel</v-icon> Denegar solicitud</v-btn>
            </v-col>
        </v-row>
      </div>

      <ConfirmationDialogComponent
          :show="dialogoConfirmacion"
          btnConfirmar="Aceptar"
          title="¿Deseas aprobar la solicitud?"
          message="De confirmarse la siguiente acción, los cambios no serán reversibles."
          @close="dialogoConfirmacion = false"
          @confirm="dialogoConfirmacion = false"
      />

      <ConfirmationDialogComponent
          :show="dialogoCancelacion"
          btnConfirmar="Aceptar"
          title="¿Deseas denegar la solicitud?"
          message="De confirmarse la siguiente acción, los cambios no serán reversibles."
          @close="dialogoCancelacion = false"
          @confirm="dialogoCancelacion = false"
      />

      <div class="my-8">
        <p class="no-margin">Proveedor</p>
        <h2 class="no-margin">Amazon</h2>
      </div>

      <div class="my-8">
        <ListadoInsumosUaciComponent />
      </div>

      <div class="my-8">
        <p>Documentos del proveedor</p>
        <!-- TODO: Agregar modal de agregar archivo -->
      </div>

      <DocumentosCargadosComponent />

      <div class="my-8">
        <router-link :to="{ name: 'uaci-aprobar-solicitud-compra', query: { space: 2 } }">
          <v-btn text><v-icon>mdi-arrow-left</v-icon> Volver</v-btn>
        </router-link>
      </div>
  </section>  
</template>

<style lang="scss" scoped>
.no-margin {
  margin: 0;
}
</style>

<script>
import { mapState } from 'vuex'
import ConfirmationDialogComponent from '../../components/ConfirmationDialogComponent.vue'
import ListadoInsumosUaciComponent from './components/ListadoInsumosUaciComponent.vue'
import DocumentosCargadosComponent from '../../components/DocumentosCargadosComponent.vue'

export default {
  name: 'verificacionproveedorView',
  components: {
    ConfirmationDialogComponent,
    ListadoInsumosUaciComponent,
    DocumentosCargadosComponent,
  },
  data: () => ({
    dialogoConfirmacion: false,
    dialogoCancelacion: false,
  }),
  computed: {
    ...mapState('comprasModule', ['insumosASolicitar'])
  },
  methods: {}
}
</script>